@import "~@fontsource/roboto/index.css";
// $back-color:#062a45;
$back-color:#232323;
$text-color:whitesmoke;

#root{
  // overflow: auto;
  // color:$text-color;
  // background-color:$back-color;
  position: relative;
  max-height: 100vh;
  &::-webkit-scrollbar-thumb {
    background: lighten($back-color,5%);
  }
  &::-webkit-scrollbar {
    background: darken($back-color,3%);
  }  
}

pre{
  margin: 0;
}